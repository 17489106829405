import { messages } from './i18n.messages'

export function i18n(language: string) {
	return {
		formatMessage: isLanguage('en', language)
			? (input: { defaultMessage: string; id?: string }) => input.defaultMessage
			: ({ defaultMessage }: { defaultMessage: string }) =>
					messages[language]?.[defaultMessage] ?? defaultMessage
	}
}
function isLanguage(expected: string, actual: string) {
	return actual.startsWith(expected)
}
